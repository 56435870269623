<template>
  <div class="navBar">
    <div class="Main setWidth">
      <div class="navBar-top">
        <div class="logo">
          <router-link class="navItem" to="home"><img class="logo" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/logo/logo%E4%B8%8B.png" alt=""></router-link>
        </div>
        <div class="phone">
              <!-- <i class="iconfont" :class="'icon-lianxidianhua1'"></i>
              <span>157-8796-7845</span> -->
          </div>
      </div>
      <ul class="navBar-bottom">
        <router-link class="navItem" to="home" tag="li">首页<div class="topBorder"><span></span></div></router-link>
        <router-link class="navItem" to="productsServe" tag="li">产品与服务<div class="topBorder"><span></span></div></router-link>
        <!-- <router-link class="navItem" to="news" tag="li">新闻<div class="topBorder"><span></span></div></router-link> -->
        <router-link class="navItem" to="about" tag="li">关于我们<div class="topBorder"><span></span></div></router-link>
        <li class="navItem" @click="handleClick">工作台<div class="topBorder"><span></span></div></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleClick() {
      window.open('//village.xiangcundanao.com/', '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
.navBar{
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
  z-index: 9;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.05000000074505806);
  .Main{
  height: 140px;
  
    .navBar-top{
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .phone{
        display: flex;
        justify-content: space-between;
          .iconfont{
            width: 16px;
            height: 13px;
            color: #000000;
            opacity: 1;
            line-height: 23px;
          }
          span{
            width: 160px;
            height: 24px;
            font-size: 20px;
            font-family: DIN-Bold, DIN;
            font-weight: bold;
            color: #000000;
            line-height: 23px;
            margin-left: 2px;
            text-align: center;
          }
      }
    }
    .navBar-bottom{      
        margin: 0 !important;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .navItem{
        cursor:pointer;
        width: 230px;
        height: 100%;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        position: relative;
        // margin-right: 30px;
        // border-top: 1px solid #333333;
        // &:hover{
        //   border-top: 1px solid #61bf7a;
        // }
        // &:last-child{
        //    margin-right: 0;
        // }
        .topBorder{
          display: block;
          width: 100%;
          height: 1px;
          border-radius: 6px 6px 6px 6px;
          opacity: 1;
          background: #333333;
          position: absolute;
          top: 0;
          span{
            display: block;
            width: 0;
            height: 1px;
            background:#64bd7c;
          }
        }
        &:hover{
          color: #64bd7c;
          .topBorder{
            span{
              width: 100%;
              transition:width 0.5s;
            }
          // background: #64bd7c;
          }
        }
      }
      .router-link-exact-active {
          color: #30C558;
        .topBorder{
          background: #30C558;
        }
         
       }
    }
      .logo{
          width: 175px;
          height: 35px;
      }
  }
}
</style>
