import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/display.css';
import './assets/index.scss'
import { Pagination, Carousel, CarouselItem, Message, Row, Col, Button } from 'element-ui';
import './assets/theme/index.css'
import axios from 'axios'
import '@/assets/icon-font/iconfont.css'// 引入矢量图标库
import 'amfe-flexible'
Vue.use(Pagination);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Button);
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.$message = Message;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
