<template>
  <div class="footer">
    <!-- <div class="footercon">
      <div class="footercon-top">
        <div class="title"> -->
    <!-- <h1>电话咨询：<span>157-8796-7845</span></h1> -->
    <!-- <h1>服务邮箱：xiangcundanao@qutanlu.com</h1>
        </div>
      </div>
    </div> -->
    <div class="footercon">
      <div class="footercon-bottom setWidth">
        <div class="footercon-bottom-item">
          <h1>四川乡村战旗数字科技有限公司</h1>
          <p>四川省成都市郫都区唐昌镇战旗村一里老街70号</p>
          <p>浙江省杭州市余杭区海外高层次人才创新园5幢712室</p>
        </div>
        <div class="footercon-bottom-item">
          <div></div>
          <!-- <img class="logo" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/logo/logo%E4%B8%8A.png" alt=""> -->
          <!-- <p>川ICP备12005532号-2 Copyright © 2019-2024</p> -->
          <a href="https://beian.miit.gov.cn/">蜀ICP备2021023763号-1</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
<style lang="scss" scoped>
.footer {
  // height: 130px;

  background: #f1f2f6;
  .footercon {
    // height: 100%;
    // width: 1220px;
    // font-size: 14px;
    // font-weight: 500;
    // color: #8C8889;
    // padding: 30px 0;
    width: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .footercon-top {
      width: 100%;
      height: 180px;

      // display: flex;
      // justify-content: center;
      // align-items: center;
      // margin: 12px 0;
      // height: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url("https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/contact.png")
        no-repeat;
      background-size: 100% 100%;
      .title {
        font-size: 36px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #ffffff;
        line-height: 42px;
        h1 {
          span {
            letter-spacing: 6px;
          }
        }
      }
      h1 {
        font-size: 28px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin: 11px 0;
      }
    }
    // margin: 0 auto;
    // justify-content: space-between;
    .footercon-bottom {
      margin: 0 auto;
      // width: 1117px;
      height: 138px;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      background: #f1f2f6;
      .footercon-bottom-item {
        height: 100%;
        display: flex;
        flex-direction: column;
        a {
          text-align: right;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #929292;
          line-height: 16px;
          text-decoration: none;
        }
        .logo {
          width: 250px;
          height: 50px;
        }
        &:nth-child(2) {
          width: 395px;
          justify-content: space-between;

          p {
            text-align: right;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #929292;
            line-height: 16px;
          }
        }
        &:nth-child(1) {
          justify-content: space-between;
          h1 {
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 23px;
            margin-bottom: 20px;
          }
          p {
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #929292;
            line-height: 16px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}
</style>