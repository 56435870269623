import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index.vue'
// import modile from '@/views/modile/index.vue'


Vue.use(VueRouter)
 var routes=[
    {
      path: '/',
      name: 'index',
      component: Index,
      redirect:'/home',
      children:[
        {
          path: '/home',
          name: 'Home',
          component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
        },
        {
          path: '/about',
          name: 'About',
          component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
        },
        {
          path: '/productsServe',
          name: 'productsServe',
          component: () => import(/* webpackChunkName: "about" */ '../views/productsServe.vue')
        },
          // {
          //   path: '/news',
          //   name: 'News',
          //   component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
          // },
          // {
          //   path: '/newsdetail',
          //   name: 'Newsdetail',
          //   component: () => import(/* webpackChunkName: "about" */ '../views/Newsdetail.vue')
          // },
      ]
    },
     {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
    },  
  ]
// alert(JSON.stringify(routes))
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    // to, from, savedPosition
    return { x: 0, y: 0 }
  }
})

export default router
